@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.amountsWrapper {
  @extend .lytFlexBetween;
  margin-bottom: 67px;
  @include media('<large') {
    margin-bottom: 40px;
    display: block;
  }
  .titleWrapper {
    @extend .lytFlexBetween;
    @include media('<large') {
      flex-direction: row;
    }
  }
  .titleWrap {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      @include media('<large') {
        font-family: 'PT Sans', sans-serif;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.24px;
      }
    }
    .amount {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
      color: $third;
      @include media('<large') {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .progressWrap {
    width: 70%;
    @extend .lytFlexBetween;
    margin-left: 37px;
    @include media('<large') {
      width: 100%;
      margin-left: 0;
      margin-top: 30px;
    }
  }
}
