@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.inputContainer {
  width: 100%;
  @extend .lytFlexRow;
  align-items: center;
  div {
    height: 54px;
  }
  .select {
    @extend .fontPTSansNormal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: $grayscale600;
  }
  .disabled {
    background: $grayscale100;
    color: $grayscale600;
    div[role='button'] {
      color: $grayscale400;
    }
  }
  fieldset {
    @extend .borderDefault;
    border-radius: 4px;
    height: auto;
  }
  .icon {
    pointer-events: none;
    position: absolute;
    right: 10px;
    height: 9px;
    width: 9px;
  }

  .iconDisabled {
    span {
      color: $grayscale300;
    }
  }

  .iconExpanded {
    transform: rotate(180deg);
  }

  .iconClose {
    cursor: pointer;
    pointer-events: auto;
    z-index: 1;
  }

  .smallIcon {
    font-size: 16px;
  }

  .small {
    div[role='button'] {
      padding: 4px 28px 4px 8px;
    }
    font-size: 12px;
  }

  .normal {
    div[role='button'] {
      padding: 10px 26px 10px 14px;
      color: rgba(2, 48, 71, 0.6);
    }
  }

  .success {
    fieldset {
      // border: none !important;
    }
    div[role='button'] {
      background-color: #eafff7;
      color: #008869;
    }
  }

  .iconSuccess {
    color: #008869;
  }

  .warning {
    fieldset {
      // border: none !important;
    }
    div[role='button'] {
      background-color: $alert50;
      color: $alertDark;
    }
  }

  .iconWarning {
    color: $alertDark;
  }
  .rounded {
    fieldset {
      border-radius: 80px;
    }
    div[role='button'] {
      border-radius: 80px;
    }
  }
  .roundedDisabled {
    border-radius: 80px;
  }
}

.tags {
  width: 225px;
  border-bottom: 1px solid $grayscale200;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.inputContainerExpanded {
  fieldset {
    // border-color: #188aa1 !important;
  }
  .normal {
    div[role='button'] {
      color: $fontsPrimary;
    }
  }
}
.inputContainerDisabled {
  fieldset {
    // border-color: rgba(0, 0, 0, 0.26) !important;
  }
}
.withSearch {
  padding: 12px;
  border-bottom: 1px solid $grayscale300;
  svg {
    width: 17px;
    height: 17px;
    margin-right: 12px;
    fill: $grayscale500;
  }
  input {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
  }
}
