@import '../../styles/commonStyling';

.sidebarBottom {
  background: $fontsPrimary60;
  & > div:nth-child(3) {
    background: $white;
    box-shadow: 0 2px 14px rgba(2, 48, 71, 0.14);
    border-radius: 20px 20px 0 0;
    padding: 16px;
    max-height: calc(100vh - 68px);
    min-height: calc(100vh - 164px);
    top: 70px;
    h2 {
      text-transform: capitalize;
    }
    .titleWrap {
      @extend .lytFlexBetween;
      margin-bottom: 16px;
    }
    .closeIcon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: $secondary;
    }
    .logout {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
    }
    .logoutIcon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
}
