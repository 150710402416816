@import '../../../styles/commonStyling';
@import '../../../styles/responsive';

.help {
  margin-top: 4px;
  .wrapper {
    @extend .lytFlexBetween;
    @include media('<large') {
      flex-direction: column;
    }
    a {
      @extend .fontPTSansNormal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: $secondary;
      @include media('<large') {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.24px;
        width: 100%;
        text-align: center;
        margin-bottom: 16px;
      }
    }
    button {
      margin-left: 10px;
      padding: 10px;
      @extend .fontPTSansBoldText;
      width: 145px;
      color: $white;
      background: $primary;
      border-radius: 40px;
      @include media('<large') {
        margin: 0;
        width: 100%;
        border-radius: 20px;
      }
    }
    .linkButtonCopied {
      background: transparent;
      border: 1px solid $primary;
      color: $primary;
      @extend .lytFlexCenter;
      svg {
        height: 17px;
        margin-left: 5px;
      }
    }
  }
}
