@import '../../../styles/commonStyling';
@import '../../../styles/responsive';

.recruiter {
  padding: 24px !important;
  width: auto !important;
  margin-right: 0 !important;
  font-weight: 400;
  p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .proxyCheckbox {
    border-top: 1px solid $secondary20;
    border-bottom: 1px solid $secondary20;
    padding: 16px 0;
    margin: 16px 0;
    & > span {
      padding: 0;
      margin-right: 4px;
    }
  }
  .proxyInput {
    font-weight: 700;
    display: flex;
    align-items: center;
    input {
      width: 166px;
      margin-left: 10px;
      padding: 14px 10px;
      @extend .fontPTSansBoldText;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .fieldError {
    margin-top: 3px;
    color: $errorREd;
  }
  .buttonsWrap {
    @extend .lytFlexBetween;
    margin-top: 16px;
    .cancel {
      @extend .fontPTSansBoldText;
      text-decoration-line: underline;
      color: $secondary;
      padding: 0;
      margin: 0;
      @include media('<large') {
        text-decoration-line: none;
        border-radius: 40px;
        padding: 8px 50px;
        border: 2px solid $secondary;
      }
    }
    .submit {
      margin: 0;
      background: $primary;
      @extend .fontPTSansBoldText;
      border-radius: 40px;
      color: $white;
      padding: 10px 50px;
      &:disabled {
        cursor: default;
        background: $primary40;
      }
    }
  }
}
