@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.pageContainer {
  @extend .lytFlexCenter;
  margin: 0 -70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media('<large') {
    margin: 0 -16px;
  }
  .background {
    position: relative;
    margin: 0 -70px;
    background-image: linear-gradient(180deg, rgba(2, 48, 71, 0) 0%, rgba(2, 48, 71, 0.4) 100%),
      url('../../assets/png/dashboardBgr.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 650px;
    width: 100%;
    max-width: 1440px;
    @include media('<large') {
      margin: 0 -16px;
      height: 400px;
    }
    @include media('<medium') {
      height: 250px;
      background-image: linear-gradient(180deg, rgba(2, 48, 71, 0) 0%, rgba(2, 48, 71, 0.4) 100%),
        url('../../assets/png/dashboardBgrMobile.png');
    }
  }
  .refLInkContainer {
    position: absolute;
    right: 70px;
    left: 70px;
    top: -40px;
    @include media('<large') {
      right: 16px;
      left: 16px;
      top: -20px;
    }
    button {
      @extend .lytFlexCenter;
      padding: 27px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      border-radius: 100px;
      border: none;
      @include media('<large') {
        padding: 10px;
        font-family: 'PT Sans', sans-serif;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.24px;
      }
    }
    .refLinkButton {
      background: $primary;
      color: $white;
      width: 100%;
    }
    .refLinkButtonCopied {
      background: $third;
      color: $white;
      width: 40%;
      svg {
        margin-right: 8px;
      }
    }
  }
  .refLinkStringWrapper {
    filter: drop-shadow(0px 2px 14px rgba(2, 48, 71, 0.14));
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 100px;
  }
  .refLinkString {
    padding: 28px 40px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include media('<large') {
      padding: 10px 20px;
      font-family: 'PT Sans', sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.24px;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  .dashBoardTitle {
    padding: 0 70px;
    margin-bottom: 70px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include media('<large') {
      margin-bottom: 40px;
      padding: 0 16px;
      & > div {
        font-size: 24px;
        line-height: 32px;
        .h4 {
          font-family: 'PT Sans', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
    h4 {
      font-weight: 600;
      @include media('<large') {
        font-family: 'PT Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
      }
    }
  }
}
