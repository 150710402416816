.h0 {
  font-weight: 700;
  font-size: 42px;
  line-height: 58px;
}
.h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 52px;
}
.h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
.h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}
.h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
