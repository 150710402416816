@import '../../styles/commonStyling';

.tableWrap {
  table {
    tr {
      th {
        @extend .fontPTSansNormal;
        border: 0;
        background: $secondary20;
        color: $fontsPrimary;
        padding: 10px 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        position: relative;
        user-select: none;
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        .thWrapper {
          display: flex;
        }
        .thWrapper {
          display: flex;
          align-items: center;
        }
        .iconWrapper {
          margin-left: 6px;
          position: relative;
          width: 9px;
          height: 14px;
        }
        .icon {
          width: 9px;
          height: 4px;
          position: absolute;
          top: 0;
          transform: translateY(-50%) rotate(180deg);
          vertical-align: middle;
        }
        .activeIcon {
          top: 100%;
          transform: translateY(-50%) rotate(0deg);
        }
      }
      td {
        @extend .fontPTSansBoldText;
        padding: 30px 20px;
        border-bottom: 1px solid $secondary20;
      }
    }
  }
  .paginationWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0;
  }
  .paginationNumbers {
    margin-left: 20px;
    ul {
      li {
        button {
          @extend .fontPTSansNormalText;
          font-weight: 700;
          margin: 0;
          padding: 0 6px;
          min-width: auto;
          height: auto;
          &:hover {
            background: $secondary10;
          }
          &[aria-current='true'] {
            color: $secondary;
            background: $secondary20;
          }
        }
        &:first-child,
        &:last-child {
          button {
            color: $secondary;
          }
        }
      }
    }
  }
  .pagination {
    p {
      @extend .fontPTSansNormalText;
      color: $fontsPrimary;
    }
    div {
      min-height: auto;
      padding-left: 0;
      & > div {
        color: $secondary;
        margin-left: 5px;
        margin-right: 0;
        &:last-child {
          display: none;
        }
      }
    }
    svg {
      color: $secondary;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .spinner {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    td {
      border: 0;
    }
  }
}
