@import 'src/styles/commonStyling';
.sidebarContainer {
  padding: 30px;
  width: 100%;
}
.loadingArea {
  margin-right: 60px;
  span {
    max-width: 38px;
    max-height: 38px;
  }
}

.sidebarItem {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  .titleWrap {
    @extend .lytFlexCenter;
  }
  .title {
    @extend .lytFlexBetween;
    margin-bottom: 15px;
  }
  .contact {
    @extend .fontPTSansNormalText;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $fontsPrimary;
    }
  }
  .iconContact {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    color: $fontsPrimary;
  }
  .subtitle {
    @extend .fontPTSansBoldText;
    margin-bottom: 4px;
  }
  .education {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      @extend .fontPTSansNormalText;
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
      b {
        font-weight: 700;
        text-transform: capitalize;
      }
      a {
        color: $fontsPrimary;
      }
      span {
        margin-top: 8px;
        align-items: center;
        display: flex;
      }
    }
  }
  .buttonDownload {
    @extend .fontPTSansBoldText;
    border: 1px solid $primary;
    border-radius: 80px;
    padding: 8px;
    color: $primary;
    width: 178px;
    @extend .lytFlexCenter;
    margin-right: 0px;
  }
  .buttonDownloadCV {
    color: $secondary;
    border-color: $secondary;
  }
  .radioGroup {
    margin-top: 16px;
    label {
      margin: 0 30px 0 0;
      &:last-child {
        margin: 0;
      }
      & > span:first-child {
        padding: 0 12px 0 0;
        & > span {
          width: 24px;
          height: 24px;
          svg {
            color: $secondary;
          }
        }
      }
      & > span:last-child {
        @extend .fontPTSansNormalText;
        text-transform: capitalize;
        color: $fontsPrimary;
      }
    }
  }
}
