@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.buttonStyle {
  background: $primary;
  color: white;
  font-size: 14px;
  width: 100%;
  height: 50px;
  border-radius: 80px;
  @extend .fontPTSansNormalText;

  &:disabled {
    background: $primary50;
    border: 1px solid $primary50;
  }
  &:hover {
    transform: scale(0.99);
  }

  // @include media('<large') {
  // }
}
.buttonStyleSecondary {
  background: $secondary;
  color: white;
  font-size: 14px;
  width: 100%;
  height: 50px;
  border-radius: 80px;
  @extend .fontPTSansBoldText;

  &:disabled {
    background: $secondary50;
  }
  &:hover {
    transform: scale(0.99);
  }
}
.buttonStyleSecondaryEmpty {
  background: white;
  color: $secondary;
  font-size: 14px;
  width: 100%;
  height: 50px;
  border-radius: 80px;
  @extend .fontPTSansBoldText;

  border: 1px solid $secondary;

  &:disabled {
    background: $secondary50;
  }
  &:hover {
    transform: scale(0.99);
  }
}
