@import '../../../styles/commonStyling';
@import '../../../styles/responsive';

.info {
  margin-top: 10px;
  @include media('<large') {
    margin-top: 0;
  }
  h3 {
    margin-bottom: 10px;
    @include media('<large') {
      margin-bottom: 8px;
      font-family: 'PT Sans', sans-serif;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.24px;
    }
  }
  ul {
    margin-bottom: 20px;
    list-style: disc;
    padding-left: 15px;
    @include media('<large') {
      padding-left: 20px;
    }
    @extend .fontPTSansNormalText;
    li {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  b {
    font-weight: 700;
  }
  a {
    color: $secondary;
    font-weight: 700;
    text-decoration: underline;
  }
  button {
    @extend .fontPTSansBoldText;
    width: 100%;
    height: 40px;
    background: $secondary;
    text-align: center;
    border: none;
    padding: 10px;
    gap: 10px;
    border-radius: 24px;
  }
}
