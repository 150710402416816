@import 'src/styles/commonStyling';

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.item {
  font-family: 'Nunito', sans-serif;
  padding: 16px 20px;
  gap: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-transform: capitalize;
  text-decoration: none;
  color: $white;
}
.active {
  background: rgba(255, 255, 255, 0.2);
  border-top: 2px solid $white;
}
