@import '../../../styles/responsive';
@import '../../../styles/commonStyling';

.container {
  width: 100%;
  max-width: 993px;

  @extend .lytFlexCenter;
  flex-direction: column;

  form,
  h1 {
    display: flex;
    flex-wrap: wrap;
    width: 426px;
    @include media('<large') {
      width: 340px;
    }
    .inputGroup {
      flex: 100%;
      margin-bottom: 18px;
      label {
        margin-bottom: 3px;
        font-size: 16px;
        span {
          color: #d72638;
          margin-left: 3px;
        }
      }
      input {
        height: 50px;
        font-size: 14px;
        padding-left: 17px;
        padding-right: 17px;
        @extend .fontPTSansBold;
      }
      button {
        height: 50px;
        border-radius: 80px;
        @extend .fontPTSansNormal;
      }
    }
  }
}
.h1TitleAccount {
  @extend .fontNunitoBoldText;
  font-size: 36px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.logoContainer {
  font-size: 4rem;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.inputGroup {
  display: flex;
  flex-direction: column;
}
.haveAccount {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @extend .fontPTSansNormalText;

  a {
    @extend .linkDefaultStying;
  }
}
.fieldError {
  margin-top: 3px;
  color: $errorREd;
}

.flexGap {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include media('>large') {
    & > * + * {
      margin-left: 20px;
    }
    flex-direction: row;
  }
}
