@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.closeIcon {
  display: flex;
  margin-left: auto;
  cursor: pointer;
  color: $secondary;
  position: relative;
  z-index: 10;
}
.defaultModalMainContainer {
  @extend .lytFlexCenter;
  overflow: scroll;
}
.defaultModalInsideContainer {
  position: absolute;
  width: 60vw;
  @include media('<large') {
    width: 72vw;
    top: 20px;
  }
  @media (orientation: landscape) {
    top: 20px;
  }

  background: $white;
  @extend .lytFlexCenter;
  flex-direction: column;
  border-radius: 16px;
  padding: 28px;
  &:focus-visible {
    outline-color: unset;
    outline: none;
  }
}
.settedHtmlText {
  h1 {
    font-weight: bold;
    font-size: 32px;
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 13px;
    margin-bottom: 13px;
  }
  ul {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  ul {
    list-style-type: square;
  }
  li {
    line-height: 24px;
    margin-bottom: 10px;
  }
  li::marker {
    color: black;
  }
}
