@import '../../../styles/responsive';
@import '../../../styles/commonStyling';

.container {
  height: 400px;
  width: 451px;
  @include media('<large') {
    width: 350px;
  }

  @extend .lytFlexCenter;
  flex-direction: column;

  p,
  h1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  p {
    font-size: 14px;
    @extend .fontPTSansNormalText;
  }
  h1 {
    font-size: 36px;
    @extend .fontPTSansBoldText;
    margin-bottom: 20px;
    margin-top: 7px;
  }
}
.congratsContainer {
  font-size: 4rem;
  width: 100%;
  height: 50px;
  width: 89px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../../assets/png/congratsLogo.png');
  margin-right: 10px;
}
.inputGroup {
  display: flex;
  flex-direction: row;
}
.inputGroupH1AndText {
  display: flex;
  flex-direction: column;
}
.inputGroup {
  width: 100%;
  margin-bottom: 20px;
  label {
    margin-bottom: 3px;
    font-size: 16px;
  }
  input {
    height: 50px;
    font-size: 14px;
    padding-left: 17px;
    padding-right: 17px;
    @extend .fontPTSansNormalText;
  }
}
