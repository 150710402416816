@import '../../../styles/commonStyling';
@import '../../../styles/responsive';

.profile {
  display: flex;
  margin-top: 10px;
  @include media('<large') {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  .avatar {
    background: $secondary;
    width: 116px;
    height: 116px;
    margin-bottom: 16px;
    margin-right: 16px;
    font-size: 42px;
    text-transform: capitalize;
  }
  .profileWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    width: 227px;
    @include media('<large') {
      width: 100%;
      justify-content: start;
      margin-bottom: 18px;
    }
  }
  .name {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    @include media('<large') {
      font-family: 'PT Sans', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.24px;
    }
  }
  .edit {
    @extend .fontPTSansNormalText;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
    @include media('<large') {
      margin-top: 0;
      margin-left: 46px;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .editIcon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    color: $secondary;
  }
  .text {
    @extend .fontPTSansNormalText;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 8px;
    }
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @include media('<large') {
        max-width: 100%;
      }
    }
    @include media('<large') {
      margin-bottom: 18px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .phoneIcon {
    margin-right: 6px;
    width: 12px;
    height: 20px;
    color: rgba(2, 48, 71, 0.6);
  }
  .emailIcon {
    margin-right: 6px;
    width: 20px;
    height: 14px;
    color: rgba(2, 48, 71, 0.6);
  }
  .locationIcon {
    color: rgba(2, 48, 71, 0.6);
  }
  .formInput {
    margin-bottom: 16px;
    label {
      @extend .fontPTSansNormalText;
      margin-bottom: 4px;
      display: block;
      span {
        color: #d72638;
      }
    }
    input {
      @extend .fontPTSansBoldText;
      border-radius: 4px;
      padding: 10px 14px;
      outline: none;
      height: auto !important;
    }
  }
  .fieldError {
    @extend .fontPTSansNormalText;
    margin-top: 3px;
    color: $errorREd;
  }
  .buttonsWrap {
    @extend .lytFlexBetween;
    margin-top: 16px;
    .cancel {
      @extend .fontPTSansBoldText;
      text-decoration-line: underline;
      color: $secondary;
      padding: 0;
      @include media('<large') {
        text-decoration-line: none;
        border-radius: 40px;
        padding: 8px 50px;
        border: 2px solid $secondary;
      }
    }
    .submit {
      background: $primary;
      @extend .fontPTSansBoldText;
      border-radius: 40px;
      color: $white;
      padding: 10px 50px;
      &:disabled {
        cursor: default;
        background: $primary40;
      }
    }
  }
}
.profileEdit {
  align-items: start;
  flex-direction: column;
  align-items: center;
}
.awardsHistory {
  margin-top: 20px;
  h1 {
    margin-bottom: 10px;
    @include media('<large') {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .tableContainer {
    max-height: 300px;
    @include media('<large') {
      max-height: 200px;
    }
  }
  table {
    tr {
      th {
        @extend .fontPTSansNormalText;
        border: 0;
        color: $fontsPrimary;
        padding: 6px 20px;
        cursor: pointer;
        background: #d7ecf2;
        border-radius: 4px 0 0 4px;
        user-select: none;
        width: 50%;
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        .iconWrapper {
          margin-left: 6px;
          position: relative;
          width: 9px;
          height: 10px;
        }
        .icon {
          width: 9px;
          height: 4px;
          position: absolute;
          top: 5px;
          transform: translateY(-50%) rotate(180deg);
          vertical-align: middle;
        }
        .activeIcon {
          top: 80%;
          transform: translateY(-50%) rotate(0deg);
        }
      }
      td {
        @extend .fontPTSansNormalText;
        padding: 16px 20px;
        border-bottom: 1px solid $secondary20;
        &:first-child {
          font-weight: 700;
        }
      }
    }
    .spinner {
      display: flex;
      justify-content: flex-end;
      margin-top: 25px;
      td {
        border: 0;
      }
    }
  }
}

.flexGap {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include media('>large') {
    & > * + * {
      margin-left: 20px;
    }
    flex-direction: row;
  }
}

.location {
  & > * + * {
    margin-left: 8px;
  }
  display: flex;
}