@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.header {
  height: 64px;
  background: $primary;
  padding: 0 70px;
  @include media('<large') {
    padding: 16px;
    background: $white;
    height: auto;
  }
  @extend .lytFlexBetween;
  .wrap {
    @extend .lytFlexBetween;
    .title {
      display: flex;
      align-items: flex-start;
      @include media('<large') {
        flex-direction: row-reverse;
      }
    }
  }
  .info {
    position: relative;
    padding: 8px 0;
    color: $white;
    cursor: pointer;
    @include media('<large') {
      padding: 0;
      color: $fontsPrimary;
    }
    &:before {
      content: '';
      background: $white;
      opacity: 0.4;
      width: 1px;
      height: 40px;
      position: absolute;
      left: -9px;
      top: 12px;
      @include media('<large') {
        content: none;
      }
    }
    .name {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      text-transform: capitalize;
      @include media('<large') {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.24px;
      }
    }
    .status {
      @extend .fontPTSansNormalText;
      text-align: right;
      text-transform: capitalize;
    }
    .avatar {
      background: $secondary;
      width: 48px;
      height: 48px;
      margin-left: 12px;
      margin-right: 10px;
      text-transform: capitalize;
      @include media('<large') {
        display: none;
      }
    }
    .badge {
      span {
        @extend .fontPTSansBoldText;
        text-align: right;
        width: 27px;
        height: 27px;
        background: $white;
        border-radius: 100%;
        color: $primary;
        transform: none;
        top: -4px;
        @include media('<large') {
          width: 20px;
          height: 20px;
          background: $primary;
          color: $white;
          top: 0;
          margin-right: 6px;
        }
      }
    }
    .icon {
      transition: transform 0.2s;
      width: 9px;
      height: 5px;
      margin-left: 10px;
      path {
        fill: $white;
        @include media('<large') {
          fill: $primary;
        }
      }
    }
    .activeIcon {
      transform: rotate(180deg);
    }
  }
  .logout {
    margin-right: 20px;
    background: #f9f9f9;
    border: 1px solid #d3d3d4;
    border-radius: 80px;
    padding: 4px 27px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .logoutIcon {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    color: $darkGrayBackground;
  }
}
