@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.logoOrange {
  height: 110px;
  min-width: 360px;
  @include media('<large') {
    width: 340px;
    min-width: unset;
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/png/logoOrange.png');
}
.logoWhite {
  height: 110px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/png/logoWhite.png');
}
.logoSmall {
  width: 40px;
  height: 40px;
  padding: 12px 0;
  gap: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/svg/logoSmall.svg');
  @include media('<large') {
    padding: 0;
    background-image: url('../../assets/svg/logoSmallOrange.svg');
  }
}
