//Common colors used on the page
$primary: #fb8500;
$secondary: #219ebc;
$third: #03ad97;
$fontsPrimary: #023047;

$primary75: rgba($primary, 0.75);
$primary60: rgba($primary, 0.6);
$primary50: rgba($primary, 0.5);
$primary40: rgba($primary, 0.4);
$primary20: rgba($primary, 0.2);
$primary10: rgba($primary, 0.1);
$primary5: rgba($primary, 0.05);

$secondary75: rgba($secondary, 0.75);
$secondary60: rgba($secondary, 0.6);
$secondary50: rgba($secondary, 0.5);
$secondary40: rgba($secondary, 0.4);
$secondary20: rgba($secondary, 0.2);
$secondary10: rgba($secondary, 0.1);
$secondary5: rgba($secondary, 0.05);

$third75: rgba($third, 0.75);
$third60: rgba($third, 0.6);
$third50: rgba($third, 0.5);
$third40: rgba($third, 0.4);
$third20: rgba($third, 0.2);
$third10: rgba($third, 0.1);
$third5: rgba($third, 0.05);

$fontsPrimary75: rgba($fontsPrimary, 0.75);
$fontsPrimary60: rgba($fontsPrimary, 0.6);
$fontsPrimary50: rgba($fontsPrimary, 0.5);
$fontsPrimary40: rgba($fontsPrimary, 0.4);
$fontsPrimary20: rgba($fontsPrimary, 0.2);
$fontsPrimary10: rgba($fontsPrimary, 0.1);
$fontsPrimary5: rgba($third, 0.05);

//TODO those are to be removed
$lightGray: #b1b1b1;
$lightGrayBackground: #d9d9d9;
$middleGrayBackground: #a6a8aa;
$darkGrayBackground: #8a8a8a;
$darkGray: #495057;
//on forms
$errorREd: #d72638;
$validGreen: #03ad97;
// on title
$white: white;

$grayscale900: #0d1c30;
$grayscale800: #1b2a3e;
$grayscale700: #314157;
$grayscale600: #43546d;
$grayscale500: #60768f;
$grayscale400: #8da0b9;
$grayscale300: #cbd4e1;
$grayscale200: #e2e8f0;
$grayscale100: #f1f5f9;
$grayscale50: #f8fafc;
$grayscale0: #ffffff;

$errorDark: #b7264d;
$errorMiddle: #fe4c5a;
$errorLight: #ffefe9;

$alertDark: #b77f1e;
$alertMiddle: #fec43e;
$alertMiddleUp: #fec43e;
$alertLight: #fffbe6;
$alert50: #fff9da;
$alert100: #583800;

//SAFARI ONLY MIXIN
@mixin safariOnly($property, $value) {
  // /* Safari only*/
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      #{$property}: $value;
    }
  }
}

//Common classes used on the page
.lytFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lytFlexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// HTML tags defaults
.inputDefaultStyle {
  border: 1px solid $darkGray;
  border-radius: 5px;
}
.linkDefaultStying {
  text-decoration: underline;
  color: $secondary;
  font-weight: bold;
}
.labelDefaultStyling {
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;
  color: $fontsPrimary !important;
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 14px !important;
}
.passwordInputDefault {
  color: $secondary;
  font-weight: bold;
  font-size: 14px !important;
}
.passwordDotsStylingDefault {
  //dots size when pass input===password
  input[type='password']:not(:placeholder-shown) {
    color: $secondary;
    font-weight: bold;
    font-size: 34px !important;
    padding-left: 17px;
    padding-right: 33px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  //pass input bgr color when is not empty
  input:not(:placeholder-shown) {
    // background: $secondary10;
  }
}

.passwordErrorDotsStyling {
  input[type='password']:not(:placeholder-shown) {
    color: $errorREd !important;
  }
  input:not(:placeholder-shown) {
    background: transparent;
  }
}

//Fonts styling

.fontPTSansNormal {
  font-family: 'PT Sans', sans-serif;
}

.fontPTSansBold {
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
}

.fontPTSansNormalText {
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.24px;
}

.fontPTSansBoldText {
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.24px;
}
.fontNunitoBoldText {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.24px;
}

//border styling
.borderDefault {
  border: 1px solid $secondary40;
  border-radius: 4px;
}

.lytFlexColumn {
  display: flex;
  flex-direction: column;
}

.lytFlexRow {
  display: flex;
  flex-direction: row;
}

.lytFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lytFlexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
