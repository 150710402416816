@import '../../../styles/responsive';
@import '../../../styles/commonStyling';

.pageContainer {
  @extend .lytFlexCenter;
  height: 100vh;
}
.container {
  flex-direction: column;
  height: 500px;
  max-width: 426px;

  @extend .lytFlexCenter;
  flex-direction: column;

  form,
  h1 {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;

    width: 426px;
    @include media('<large') {
      width: 340px;
    }
    .inputGroup {
      flex: 100%;
      margin-bottom: 18px;
      label {
        margin-bottom: 4px;
        font-size: 16px;
        span {
          color: #d72638;
          margin-left: 3px;
        }
      }
      input {
        height: 50px;
        font-size: 14px;
        padding-left: 17px;
        padding-right: 17px;
        @extend .fontPTSansNormalText;
        color: $secondary;
      }
    }
  }
}
.passwordSuggestions {
  p {
    svg {
      margin-right: 2px;
      color: $secondary;
      font-size: 16px;
    }
    @extend .fontPTSansNormalText;
    color: $fontsPrimary;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 7px;
  }
}
.passwordTitle {
  @extend .fontNunitoBoldText;
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 36px;
}
.logoContainer {
  width: 100%;
  font-size: 4rem;
  margin-bottom: 40px;
}
.inputGroup {
  display: flex;
  flex-direction: column;
}
.haveAccount {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @extend .fontPTSansNormalText;

  a {
    @extend .linkDefaultStying;
  }
}
.createAccount {
  a {
    color: $primary;
  }
}
.fieldError {
  margin-top: 3px;
  color: $errorREd;
}
.confirmBtnRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
