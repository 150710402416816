@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.defaultStyle {
  @extend .inputDefaultStyle;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  input {
    width: 100%;
    @extend .borderDefault;
  }
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $fontsPrimary60;
    // opacity: 1; /* Firefox */
  }
}
.extendInputContainer {
  width: 106%;
}
.passwordInputStyling {
  @extend .passwordInputDefault;
}
.passwordDotsStyling {
  @extend .passwordDotsStylingDefault;
}
.passwordErrorDotsStyling {
  @extend .passwordDotsStylingDefault;
}

.visibilityIconContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 15px;
  left: -34px;
  cursor: pointer;
}
.tags {
  max-width: 40%;
  overflow-x: auto;
  display: flex;

  & > * + * {
    margin-left: 4px !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
