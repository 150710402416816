@import 'src/styles/commonStyling';

.breadcrumbs {
  @extend .fontPTSansNormalText;
  text-transform: capitalize;
  a {
    color: $fontsPrimary60;
    text-decoration: none;
  }
  .last {
    color: $fontsPrimary;
    font-weight: 700;
  }
}
