@import '../../../../styles/responsive';
@import '../../../../styles/commonStyling';

.passwordSuggestions {
  p {
    svg {
      margin-right: 2px;
      color: $secondary;
      font-size: 16px;
    }
    @extend .fontPTSansNormalText;
    color: $fontsPrimary;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 7px;
  }
}

.validLine {
  color: $validGreen !important;
  svg {
    color: $validGreen !important;
  }
}
.errorLine {
  color: $errorREd !important;
  svg {
    color: $errorREd !important;
  }
}
