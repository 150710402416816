@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.defaultStyle {
  @extend .inputDefaultStyle;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  input {
    width: 100%;
    @extend .borderDefault;
  }
  input::placeholder {
    color: $fontsPrimary60;
  }
}
