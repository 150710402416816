@import '../../styles/commonStyling';

.popper {
  z-index: 1111;
}

.content {
  background: white;
  padding: 30px;
  box-shadow: 0 2px 14px rgba(2, 48, 71, 0.14);
  border-radius: 4px;
  margin-top: 6px;
  margin-right: 70px;
  width: 360px;
  .header {
    @extend .lytFlexBetween;
    .closeIcon {
      width: 35px;
      height: 35px;
      cursor: pointer;
      color: $secondary;
    }
  }
  .logout {
    @extend .fontPTSansNormalText;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .logoutIcon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    color: $secondary;
  }
}
