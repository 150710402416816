@import '../../../styles/responsive';
@import '../../../styles/commonStyling';

.termsContainer {
  display: flex;
  flex-direction: column;
  * {
    font-size: 14px !important;
  }
  ol {
    padding-inline-start: 20px !important;
    margin-bottom: 12px !important;
  }
  p {
    margin-left: 5px !important;
  }
}
