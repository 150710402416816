@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.datePicker {
  display: flex;
  align-items: center;
  background: $grayscale0;
  border: 1px solid $grayscale300;
  border-radius: 4px;
  padding: 0 12px;
  & > span {
    color: $grayscale500;
    font-size: 20px !important;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  input {
    background-color: transparent;
    width: 100%;
    font-size: 16px;
    @extend .fontPTSansNormal;
    border: none;
    outline: none;
  }
}
